/* // XX-Large devices (larger desktops, 1400px and up) */

@media (min-width: 2000px) {

}

@media (max-width: 1400px) {
}


/* // X-Large devices (large desktops, 1200px and up) */

@media (max-width: 1200px) {
    .slider-imagen{
        height: 700px;
        width: 100%;
    }
    
   
}



/* // Large devices (desktops, 992px and up) */

@media (max-width: 992px) {
    .Imagen-Taller{
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    .slider-imagen{
        height: 600px;
        width: 100%;
    }

    .redesSocialesCard{

        margin-top: 2%;
    }
    
}

@media (max-height: 925px) {
    body {
        margin-top: -3%;
        
    }
    .vh100 {
        height: 70vh;
        padding-bottom: 0%;
    }
    .wizard {
        top: 30%;
        margin-top: 25%;
        max-height: 600px;
    }
    .crearCuentaForm{
        margin-top: 15vh;
        margin-bottom: 10vh;
    }
    
}

@media (max-height: 1025px) {
    .wizard {
        margin-top: 10%;
    }
}

@media (max-width: 900px) {
   
    .cerrarSesion{
        margin-top:40% !important
    }
}



/* // Large devices iphone 12 pro (desktops, 992px and up) */

@media (max-width: 850px) {
    /* header */
    .logo{
        width: 90px;
    }
    .logoSecum {
        width: 125px;
    }
    
    .logoMichoacan {
        width: 130px;
        height: 50px;
    }

    .link{
        font-size: 12px;
    }
   
    /* Texto en el bg-Guinda  */

    .text-big {
        font-size: 20px;
    }

    .cerrarSesion{
        margin-top:40% !important
    }

    .slider-imagen{
        height: 500px;
        width: 100%;
    }
   

}


/* // Medium devices (tablets, 768px and up) */

@media (max-width: 800px) {
    .logo{
        width: 120px;
    }
    .logoSecum {
        width: 115px;
    }
    
    .logoMichoacan {
        width: 120px;
        height: 50px;
    }

    .link{
        font-size: 11px;
    }
    
    .boxModals{
        width: 95%;
        max-height: 80vh;
    }

    .Imagen-Taller{
        width: 100%;
        height: 300px;
        object-fit: cover;
    }


}

@media (max-width: 776px){
    /* header */
    .logo{
        width: 120px;
    }
    .logoSecum {
        width: 115px;
    }
    
    .logoMichoacan {
        width: 120px;
        height: 50px;
    }

    .link{
        font-size: 11px;
    }
    .card{
        max-height: none;
        height: fit-content;
    }

    .mapa{
        height: 300px;
    }
    .mobile-container{
        margin-top: 90px;
    }
    .flexmobile{
        flex-direction: column!important;
    }
    .rb1{
        width: 100%;
        height: 30px;
    }
    .slider-imagen{
        height: 400px;
        width: 100%;
    }
    .imgUsuarioView {
        width: 90vw;
    }
    .modal-content {
        max-width: 95%;
        max-height: 80vh;
        padding: 10px;
    }
    .responsive-pdf {
        width: 100%;
        height: 80%;
    }
    .swiper-container {
        height: 200px;
    }
    
}


@media (max-width: 726px){
    /* header */
    .logo{
        width: 100px;
    }
    .logoSecum {
        width: 105px;
    }
    
    .logoMichoacan {
        width: 100px;
        height: 50px;
    }

    .link{
        font-size: 10px;
    }

}

@media (max-width: 637px){
    /* header */
    .logo{
        width: 80px;
    }
    .logoSecum {
        width: 90px;
    }
    
    .logoMichoacan {
        width: 80px;
        height: 40px;
    }

    .link{
        font-size: 10px;
    }

}



@media (min-width: 600px) {

}

@media (max-width: 576px) {
    .col-3 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (max-width: 450px) {
    .text-small{
        font-size: 11px;
    }
    .modal-content {
        max-width: 100%;
        max-height: 75vh;
        padding: 5px;
    }
    .responsive-pdf {
        width: 100%;
        height: 70%;
        margin-top: -40%;
    }
    .vh100 {
        height: 70vh;
        padding-bottom: 0%;
    }
    .loader {
        left: 45%;
        top: 50%;
    }
    .wizard {
        max-width: 95%;
        max-height: 120%;
        top: 50%;
    }
    .fotopopUp{
        width: 400px;
        height: 100%;
    }
    

}

@media (max-width: 300px) {
    .fotopopUp{
        width: 90%;
        height: 100%;
    }
}

