:root {
  --guinda: #4a001f;
  --verdePastel: #2c9d8f;
  --verdeOscuro: #264754;
  --azulOscuro: #414f55;
  --morado: #69164a;
  --rosaPastel: #ffc3d0;
  --rosaClaro: #ffe6ec;
  --input-gray: #f0f2f5;
  --white: #ffffff;
  --gray-00: #f5f6f7;
  --gray-05: #f2f3f5;
  --gray-10: #ebedf0;
  --gray-20: #dadde1;
  --gray-25: #ccd0d5;
  --gray-30: #bec3c9;
  --gray-45: #8d949e;
  --gray-50: #e6e5e9;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1c1e21;
  --lightblue-alpha: rgba(75, 115, 240, 0.1);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --overlay-alpha-80: rgba(244, 244, 244, 0.8);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --animate-duration: 0.7s;
  --primary: #007bff;
  --success: #28a745;
  --danger: #dc3545;
  --warning: #ffc107;
}

@font-face {
  font-family: "Gibson-Bold";
  src: url("../assets/fonts/Gibson-Bold.otf");
}

@font-face {
  font-family: "Gibson-Regular";
  src: url("../assets/fonts/Gibson-Regular.otf");
}

.GibsonBold {
  font-family: "Gibson-Bold";
}

.GibsonRegular {
  font-family: "Gibson-Regular";
}

.text-big {
  font-size: 30px;
}

.text-medium {
  font-size: 20px;
}
.text-normal {
  font-size: 15px;
}

.text-small {
  font-size: 11px;
}

.text--gray {
  color: gray;
}

.text--verdeOscuro {
  color: var(--verdeOscuro);
}

.text--morado {
  color: var(--morado);
}

.text--guinda {
  color: var(--guinda);
}

.text-bold {
  font-family: Gibson-Bold;
}

.bg-azulOscuro {
  background-color: var(--azulOscuro);
}

.bg-verdePastel {
  background-color: /*var(--gray-30)*/ var(--rosaPastel);
}

.bg-morado {
  background-color: var(--morado);
}
.bg-gray {
  background-color: var(--gray-30);
}
.bg-fondo {
  background-image: url("../assets//images/inicio_sesion.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-gray-00 {
  background-color: #c1c1c1;
}

.cta {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cta:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.cta--guinda {
  color: white;
  background-color: var(--morado) /*  var(--gray-45) */ !important;
}

.cta--guinda_disabled {
  color: white;
  background-color: var(--rosaPastel) !important;
}

.cta--small {
  max-width: 200px;
}

.cta--xs {
  max-width: 140px;
}

/*---------------- Estilos del Scroll ------------------ */

/* width */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}

/* Handle */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: /*var(--gray-100)*/ var(--morado);
  cursor: pointer;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.link {
  font-size: 14px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 5px;
}

.link:hover {
  color: white;
  background-color:   /*var(--gray-30)*/ var(--morado);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.cursor-pointer {
  cursor: pointer;
}

/*---------------- Estilos Header y Footer------------------ */

.header {
  height: 80px;
  border-bottom: 5px solid /*var(--gray-45)*/ var(--morado);
  background: white;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  bottom: 0;
}
.footer {
  border-top: 5px solid var(--morado);
}

.logo {
  width: 100px;
  /* background-color: var(--morado); */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.logoSecum {
  width: 130px;
}

.logoMichoacan {
  width: 200px;
  height: 59px;
}
.logoAniversario {
  width: 200px;
}

/* --------------- Estilos Carousel--------------- */

#app {
  height: 100%;
}

html,
body {
  margin-top: 10vh;
  position: relative;
  height: 100%;
  width: 100%;
  letter-spacing: 1.1px;
}

body {
  font-family: "Gibson-Regular";
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-position: 50% 0%;
  object-fit: cover;
}

.swiper-button-prev::after {
  background-color: white;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
  color: var(--verdeOscuro);
  /* border: 1px solid red; */
}

.swiper-button-next::after {
  background-color: white;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px !important;
  color: var(--verdeOscuro);
}

.accordion-button:not(.collapsed) {
  background-color: var(--guinda) /* var(--gray-70)  */;
  color: white;
}

.full-screen-container {
  min-height: 90vh;
}

/* ------------------------estilos de texto y background verde-------------- */

.container-verde {
  background-color: /*var(--gray-30)*/ var(--morado);
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-radius: 10px; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.card {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  max-height: 500px;
  overflow-y: auto;
}
.redesSocialesCard {
  height: 200px;
}
.cardFormulario {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow:  0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.crearCuentaForm{
  margin-top: 5%;
  margin-bottom: 5%;
}

.img--news {
  border-radius: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: 100%;
}

.container_img {
  height: 180px;
  max-width: 250px;
  overflow: hidden;
}

.imgUsuarioView {
  width: 100vh;
}

/* --------------- Estilos Noticias--------------- */

.borderBottomTitulo {
  border-bottom: 1.5px solid var(--verdePastel);
}

.iconoBtn {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: var(--verdePastel);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.descripcionHome {
  text-align: justify;
}

.iconoBtn:hover {
  background-color: var(--verdePastel);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  color: white;
}

/* -------------Estilos de talleres---------------------- */

.Imagen-Taller {
  background-image: url("../assets/images/talleres.png");
  width: 600px;
  height: 500px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.mapa {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

/* -------------Estilos de Login---------------------- */

.float-container {
  border: solid 1px #ccc;
  padding: 8px;
  position: relative;
}

.float-container input {
  border: none;
  font-size: 16px;
  margin: 10px 5px 0 5px;
}

label:not(.MuiFormLabel-root) {
  font-size: 16px;
  position: absolute;
  margin: -8px 8px;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
}

/* :not(.MuiFormLabel-root){

} */

.float-container--active input {
  border: none;
  font-size: 16px;
  margin: 10px 5px 0 5px;
}

.float-container--active {
  border: solid 1px var(--morado) /* var(--gray-45)  */;
  padding: 8px;
  position: relative;
}

.label--active {
  color: var(--morado) /* var(--gray-45) */;
  transform: translate(0, 4px) scale(0.75);
}

input:focus {
  outline: none;
}

/*--------------- PopUp--------------------------- */

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 640px;
  left: 0px;
  top: 0px;
  background-color: var(--black-alpha-30);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.8px);
  z-index: 100;
  cursor: auto;
  overflow-y: hidden;
}

.overlay--transparent {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

.overlay--mid {
  z-index: 10 !important;
}

.wizard {
  width: 100%;
  max-width: 800px;
  background-color: var(--white);
  box-shadow: 3px 3px 12px 0px var(--shadow-2);
  border-radius: 10px;
  z-index: 100;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  overflow-y:auto;
}

.fotopopUp{
  width: 100%;
  height: 100%;
}

/* ----------Radio Button----------------- */

input[type="radio"] + label {
  cursor: pointer;
}

input[type="radio"] + label:before {
  content: "";
  width: 25px;
  height: 25px;
  float: left;
  margin: 0px 10px 0 0;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 100%;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  color: var(--guinda) /*  var(--gray-70) */;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 23px;
  height: 23px;
  background-color: var(--guinda) /*var(--gray-70) */;
  border: 5px solid white;
  float: left;
  margin-left: -34px;
  margin-top: 1px;
  border-radius: 100%;
}

input[type="radio"]:checked + label::before {
  border: 8px solid /*var(--gray-70)*/ var(--guinda);
}

.rb1 {
  /* margin-right: 80px !important; */
  width: 150px;
  height: 30px;
}

.marginforCB {
  margin-top: 1.4rem;
}

.logoGris {
  width: 150px;
}

.logoSello {
  width: 70px;
}

.redes {
  width: 400px;
  color:/*var(--gray-70) */ var(--morado);
}

.pointer {
  cursor: pointer;
}

/*------------------------------ Notificaciones ----------------------- */

.notificacion {
  position: relative;
  bottom: 10px;
  margin-bottom: 5px;
  z-index: 1024;
  background-color: var(--white);
  min-width: 360px;
  max-width: 480px;
  min-height: 80px;
  box-shadow: -20px 30px 50px 20px var(--black-alpha-05);
  padding: 10px 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.notificacion--success {
  border-left: 3px solid var(--green);
}

.notificacion--error {
  border-left: 3px solid red;
}

.notificacion--warning {
  border-left: 3px solid var(--light-orange);
}

.notificacion--option {
  border-left: 3px solid var(--morado);
}

.notificacion-animate {
  opacity: 1 !important;
  right: 10px !important;
}

.notification-animate-enter {
  opacity: 0;
  right: 0;
}

.notification-animate-enter-active {
  opacity: 1;
  right: 10px;
  transition: all 200ms;
}

.notification-animate-exit {
  opacity: 1;
  right: 10px;
}

.notification-animate-exit-active {
  opacity: 0;
  right: 0px;
  transition: all 200ms;
}

.notificacions {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  bottom: 0px;
  right: 0px;
}

.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
}

.bg-rosaClaro {
  background-color: var(--rosaClaro);
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  left: 50%;
  top: 50%;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 2px solid /*var(--gray-80)*/ #69164a;
  position: absolute;
  left: 0;
  top: 0;
  animation: rotationBreak 3s ease-in-out infinite alternate;
}
.loader::after {
  border-color:/*var(--gray-45)*/ #fb00ff;
  animation-direction: alternate-reverse;
}

@keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-container {
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch; 
}

.boxModals {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 25px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: hidden;
  overflow-y: scroll;
}

.modal-content {
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.responsive-pdf {
  width: 100%;
  height: 90%;
  max-height: 1000px;
}
.vh100 {
  height: 90vh;
  padding-bottom: 10%;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.cerrarSesion {
  margin-top: 60% !important;
}

.textarea {
  overflow: hidden;
}
.swal2-container {
  z-index: 100002 !important;
}

td.break {
  word-break: break-all;
}

.slider-imagen {
  height: 900px;
  width: 100%;
}

.hover-profile {
  padding: 0.5rem 0.8rem;
}

.hover-profile:hover {
  background-color: #3033381b !important;
}

.btn-drop {
  background-color: var(--guinda);
  color: white;
}
